/**
 *
 * @param {string} eventName
 * @param {string} elementSelector
 * @param {function} handler
 */
export function delegateEvent(eventName, elementSelector, handler) {
    document.addEventListener(eventName, function (e) {
        for (let target = e.target; target && target !== this; target = target.parentNode) {
            if (target.matches(elementSelector)) {
                handler.call(target, e)
                break
            }
        }
    }, false)
}

export function showDesktopLanguages() {
    const desktopLanguagesMenu = document.getElementsByClassName("desktop-languages")[0],
        desktopLanguageLink = document.getElementsByClassName("arrow-down")[0]
    desktopLanguagesMenu.classList.toggle("show")
    desktopLanguageLink.classList.toggle("rotate")
    return false
}

export function showMobileLanguages() {
    return false
}

export const pipe = (...fns) => (x) => fns.reduce((v, f) => f(v), x)